export const navItems = [
  {
    name: "Home",
    link: "#landing",
  },
  {
    name: "About",
    link: "#about",
  },
  {
    name: "Experience",
    link: "#project1",
  },
  {
    name: "Contact",
    link: "#contact",
  },
];
